import Loading from 'components/loading/Loading';
import React, { useEffect } from 'react'

const Redirect = () => {
  
  function redirectToApp() {
    const appStoreURL = 'https://apps.apple.com/app/id6575384080';
    const playStoreURL = 'https://play.google.com/store/apps/details?id=com.partof';  
    const webURL = 'https://partof.ch'; 

    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipod|ipad/.test(userAgent);
    const isIPad = /ipad/.test(navigator.platform.toLowerCase()) || (userAgent.includes("mac") && "ontouchend" in document);
    const isAndroid = /android/.test(userAgent);

    if (isIOS || isIPad) {
        console.log('Redirecting to App Store...');
        window.location.href = appStoreURL; 
    } else if (isAndroid) {
        console.log('Redirecting to Play Store...');
        window.location.href = playStoreURL; 
    } else {
        console.log('Not on a mobile device. Redirecting to website.');
        window.location = webURL;
    }
}

  useEffect(() => {
    redirectToApp()
  }, [])

  return (
    <div className='flex flex-col justify-center items-center'>
      <Loading />
      <h1 className='text-purple text-lg font-semibold'>Redirecting...</h1>
    </div>
  )
}

export default Redirect