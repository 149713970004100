import Loading from "components/loading/Loading";
import MobileModal from "components/mobileModal/MobileModal";
import QRCodeModal from "components/qrCodeModal/QRModal";
import PublicLayout from "layout/publicLayout/PublicLayout";
import Contactus from "pages/Contactus/Contactus";
import Policy from "pages/Policy/Policy";
import Redirect from "pages/Redirect/Redirect";
import ActivityPage from "pages/activityPage/ActivityPage";
import HomePage from "pages/homePage/HomePage";
import MerchantPage from "pages/merchantPage/MerchantPage";
import NotFound from "pages/notFound/NotFound";
import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function App() {
  const [loading, setLoading] = useState(false)

  // function openApp() {
  //   setLoading(true)
  //   let appScheme = `part-of://`;
  //   const url = window.location.href;
  //   const baseURL = 'https://partof.ch';

  //   if (url.startsWith(baseURL)) {
  //     const urlPath = url.slice(baseURL.length);
  //     const urlParts = urlPath.split('/');

  //     if (urlParts[1] === 'merchant') {
  //       const uuid = urlParts[2];
  //       appScheme += `commerce/${uuid}`;
  //     }
  //   }

  //   const userAgent = navigator.userAgent.toLowerCase();
  //   const isIOS = /iphone|ipod|ipad/.test(userAgent);
  //   const isAndroid = /android/.test(userAgent);

  //   if (isIOS || isAndroid) {
  //     const startTime = Date.now();
  //     window.location = appScheme;

  //     const fallbackURL = 'https://partof.ch';
  //     setTimeout(() => {
  //       if (Date.now() - startTime < 1500) {
  //         console.log("The app is not installed.");
  //         window.location = fallbackURL;
  //       }
  //     }, 1500);
  //   }
  //   setLoading(false)
  // }
  function openApp() {

    if (sessionStorage.getItem('isRedirecting')) {
      setLoading(false);
      return; 
    }
  
    sessionStorage.setItem('isRedirecting', 'true');
    
    setLoading(true);
    let appScheme = `part-of://`;
    const url = window.location.href;
    const baseURL = 'https://partof.ch/'; 
    let universalLink = baseURL;
  
    if (url.startsWith(baseURL)) {
      const urlPath = url.slice(baseURL.length);
      const urlParts = urlPath.split('/');
  
      if (urlParts[1] === 'merchant') {
        const uuid = urlParts[2];
        appScheme += `commerce/${uuid}`; 
        universalLink += `commerce/${uuid}`; 
      }
    }
  
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = /android/.test(userAgent);
    const isIOS = /iphone|ipod|ipad/.test(userAgent);
  
    if (isAndroid) {
      // const startTime = Date.now();
      window.location = appScheme; 
  
      // const fallbackURL = 'https://partof.ch/';
      // setTimeout(() => {
      //   if (Date.now() - startTime < 2000) {
      //     console.log("The app is not installed.");
      //     window.location = fallbackURL; 
      //   }
      // }, 1500);
    } else if (isIOS) {
      window.location = universalLink;
    }
  
    setLoading(false);
  }

  useEffect(() => {
    openApp();
  }, [])


  const router = createBrowserRouter([
    {
      path: "/",
      element: <PublicLayout />,
      errorElement: <NotFound parent={true} />,  // You can keep this for global error handling
      children: [
        {
          path: "",
          element: <HomePage />,
        },
        {
          path: "redirect",
          element: <Redirect />,
        },
        {
          path: "merchant/:id",
          element: <MerchantPage />,
          handle: { layoutProp: true },
        },
        {
          path: "activity/:id",
          element: <ActivityPage />,
        },
        {
          path: "contactus",
          element: <Contactus />,
        },
        {
          path: "policies",
          element: <Policy />,
        },
        // Catch-all route for invalid paths and redirect to the home page
        {
          path: "*",
          element: <HomePage />,  // This can redirect to the home page or any other fallback
        },
      ],
    },
  ]);

  return (
    loading ?
      <Loading />
      : <>
        <RouterProvider router={router} />
        <MobileModal />
        <QRCodeModal />
      </>
  );
}

export default App;