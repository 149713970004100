import React from "react";
import MobileLayout from "./mobileLayout/MobileLayout";
import DesktopLayout from "./desktopLayout/DesktopLayout";
import useMediaQuery from "hooks/useMediaQuery";
const PublicLayout = () => {
  const isMobile = useMediaQuery("(max-width: 640px)");

  return (
      <div>
        {isMobile ? (
          <MobileLayout  />
        ) : (
          <DesktopLayout />
        )}
      </div>
  );
};

export default PublicLayout;
