import React, { useState } from 'react'

const Contactus = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Create a mailto link with pre-filled subject and body
        const mailtoLink = `mailto:contact@example.com?subject=Message%20from%20${encodeURIComponent(formData.name)}&body=${encodeURIComponent(formData.message)}%0A%0AFrom: ${encodeURIComponent(formData.email)}`;
    
        // Redirect user to their email client with the mailto link
        window.location.href = mailtoLink;
      };

    return (
        <div className='flex flex-col text-center justify-center'>
            <h1 className='text-center font-semibold text-3xl text-purple w-full'>Contactez-Nous</h1>
            <p className="text-center text-lg text-gray-600 mb-8">
          Nous serions ravis de recevoir vos questions ou commentaires. Complétez le formulaire ci-dessous pour nous contacter.
        </p>

        <form onSubmit={handleSubmit} className='lg:px-56 px-4'>
          <div className="space-y-6">
            {/* Name Input */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Nom
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Adresse e-mail
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Message Textarea */}
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              ></textarea>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-indigo-600 text-white font-medium rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Envoyer
              </button>
            </div>
          </div>
        </form>

        {/* Contact Info */}
        <div className="mt-10 text-center">
          <h2 className="text-xl font-semibold text-gray-800">Autres moyens de nous contacter :</h2>
          <p className="text-lg text-gray-600 mt-2">
            <strong>Email :</strong> <a href="mailto:support@partof.ch" className="text-indigo-600">support@partof.ch</a>
          </p>
          {/* <p className="text-lg text-gray-600 mt-2">
            <strong>Téléphone :</strong> <span className="text-indigo-600">+41 12 345 67 89</span>
          </p> */}
          {/* <p className="text-lg text-gray-600 mt-2">
            <strong>Adresse :</strong> 123 Rue Exemple, 1000 Genève, Suisse
          </p> */}
        </div>
        </div>
    )
}

export default Contactus