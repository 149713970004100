import Loading from "components/loading/Loading";
import { getMerchantById } from "config/merchantServices";
import { formatTimeToFrench, truncateString } from "helpers/Helpers";
import NotFound from "pages/notFound/NotFound";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MerchantPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [merchant, setMerchant] = useState();

  useEffect(() => {
    const fetchMerchant = async () => {
      try {
        setLoading(true);
        const merchantData = await getMerchantById(id);
        if (merchantData.status === 200) {
          setMerchant(merchantData?.data?.data);
        } else {
          setMerchant();
        }
      } catch (e) {
        // console.error(e);
      } finally {
        setLoading(false);
      }
    };
    fetchMerchant();
  }, [id]);

  return (
    <>
      {!loading ? (
        merchant?.id ?
        <div className={`rounded-lg flex flex-col h-full w-full max-w-[650px] relative p-3 text-center text-orange`}>
          <div className={`bg-white rounded-full font-bold w-20 h-20 absolute top-0 right-0 bottom-2 border-2 flex bg-white items-center justify-center text-xs`}>
            {merchant?.defaultType === 2 ? 'Commerce' : merchant?.defaultType === 3 ? 'Vigneron' : 'Association'}
          </div>
          <div className={`flex flex-col w-full h-full border-2 border-orange rounded-3xl gap-2 p-4 bg-white overflow-y-auto max-h-full`}>
            <img
              src={
                merchant?.images?.length > 0
                  ? merchant?.images[0]
                  : "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
              }
              alt={merchant?.title}
              className="max-h-52 max-w-52 w-full h-full rounded-full self-center"
            />
            {/* Content Section */}
            <div className="flex flex-col gap-2">
              <h3 className={`text-xl font-bold`}>{merchant?.title}</h3>
              <p className={`text-gray-500 px-4 text-sm`}>
                {truncateString(merchant?.bio, 120)}
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 text-left text-base">
              <div className="text-left text-base">
                ADRESSE
                <br />
                <span className="text-base font-bold">
                  {merchant?.address ? merchant?.address : "-"}
                </span>
              </div>
              <div className="text-left text-base">
                TELEPHONE
                <br />
                <span className="text-base font-bold">
                  {merchant?.phone ? merchant?.phone : "-"}
                </span>
              </div>
              <div className="text-left text-base">
                SITE WEB OFFICIEL
                <br />
                <span className="text-base font-bold">
                  {merchant?.website ? merchant?.website : "-"}
                </span>
              </div>
              <div className="text-left text-base">
                HORAIRES DE TRAVAIL EN SEMAINE
                <br />
                <span className="text-base font-bold">
                  {merchant?.workingHours?.weekday?.endTime &&
                  merchant?.workingHours?.weekday?.startTime
                    ? formatTimeToFrench(merchant?.workingHours?.weekday?.startTime, merchant?.workingHours?.weekday?.endTime)
                    : "-"}
                </span>
              </div>
              <div className="text-left text-base">
                HORAIRES DE TRAVAIL LE WEEK-END
                <br />
                <span className="text-base font-bold">
                  {merchant?.workingHours?.weekend?.endTime &&
                  merchant?.workingHours?.weekend?.startTime
                    ? formatTimeToFrench(
                        merchant?.workingHours?.weekend?.startTime,
                        merchant?.workingHours?.weekend?.endTime
                      )
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        : <NotFound />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MerchantPage;
