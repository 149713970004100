import React from 'react'

const Policy = () => {
    return (
        <div className='flex flex-col'>
            <h1 className='text-center font-semibold text-3xl text-purple w-full'>Politique de Confidentialité de PartOf</h1>
            <p className="text-sm text-gray-500 mb-6 text-center">
                Date d'entrée en vigueur : <strong>1er mars 2025</strong>
            </p>

            <div className="space-y-6 text-lg text-gray-700">
                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        1. Collecte des Données Personnelles
                    </h2>
                    <p>
                        Lors de l'utilisation de PartOf, nous pouvons collecter les informations suivantes :
                    </p>
                    <ul className="list-inside list-disc">
                        <li>Informations d’inscription : Nom, prénom, adresse e-mail, numéro de téléphone.</li>
                        <li>Données de profil : Photo de profil, description personnelle, préférences d’activités.</li>
                        <li>Données de localisation : Si vous activez les services de localisation, nous collectons votre position pour personnaliser votre expérience.</li>
                        <li>Données d'utilisation : Historique de navigation, interactions avec d'autres utilisateurs et activités sur l'application.</li>
                        <li>Données de caméra : Nous collectons des données via la caméra pour des fonctionnalités spécifiques, comme la numérisation de QR codes. Seuls les QR codes pertinents pour la fonctionnalité de l'application seront enregistrés, ainsi que votre identifiant utilisateur pour suivre l'activité.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        2. Utilisation des Données
                    </h2>
                    <p>
                        Vos données sont utilisées pour :
                    </p>
                    <ul className="list-inside list-disc">
                        <li>Permettre votre inscription et l'utilisation des services de PartOf.</li>
                        <li>Vous connecter avec d'autres membres et professionnels locaux.</li>
                        <li>Vous proposer des offres, événements et activités adaptés.</li>
                        <li>Améliorer notre plateforme et personnaliser votre expérience.</li>
                        <li>Garantir la sécurité et la conformité de l'application.</li>
                        <li>Numériser les QR codes dans le cadre de l'utilisation de l'application, lorsque cela est pertinent pour la fonctionnalité de l'application.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        3. Partage des Données
                    </h2>
                    <p>
                        Nous ne partageons pas vos données personnelles avec des tiers sans votre consentement, sauf dans les cas suivants :
                    </p>
                    <ul className="list-inside list-disc">
                        <li>Avec les partenaires locaux (viticulteurs, commerçants, associations) lorsque vous interagissez avec leurs offres ou événements.</li>
                        <li>Pour se conformer aux obligations légales et réglementaires.</li>
                        <li>Avec des prestataires de services tiers qui nous aident à exploiter l'application (ex. hébergement, maintenance).</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        4. Conservation des Données
                    </h2>
                    <p>
                        Nous conservons vos données aussi longtemps que nécessaire pour fournir nos services et respecter nos obligations légales. Vous pouvez demander la suppression de vos données à tout moment via les paramètres de l'application ou en nous contactant.
                    </p>
                </section>

                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        5. Sécurité des Données
                    </h2>
                    <p>
                        Nous mettons en place des mesures de sécurité techniques et organisationnelles pour protéger vos données contre tout accès non autorisé, altération ou perte.
                    </p>
                </section>

                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        6. Vos Droits
                    </h2>
                    <p>
                        Conformément à la législation en vigueur, vous disposez des droits suivants :
                    </p>
                    <ul className="list-inside list-disc">
                        <li>Accès à vos données personnelles.</li>
                        <li>Rectification de vos informations.</li>
                        <li>Suppression de vos données.</li>
                        <li>Opposition à l'utilisation de vos données.</li>
                        <li>Portabilité de vos données.</li>
                    </ul>
                    <p>
                        Vous pouvez exercer ces droits en nous contactant à l'adresse suivante : <strong className='underline'>support@partof.ch</strong>.
                    </p>
                </section>

                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        7. Cookies et Technologies de Suivi
                    </h2>
                    <p>
                        PartOf utilise des cookies et technologies similaires pour améliorer votre expérience utilisateur. Vous pouvez gérer vos préférences de cookies via les paramètres de votre appareil.
                    </p>
                </section>

                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        8. Modifications de la Politique de Confidentialité
                    </h2>
                    <p>
                        Nous nous réservons le droit de modifier cette politique à tout moment. Toute modification sera notifiée via l'application ou par e-mail.
                    </p>
                </section>

                <section>
                    <h2 className="text-xl font-semibold text-gray-900">
                        Contact
                    </h2>
                    <p>
                        Si vous avez des questions concernant cette politique, vous pouvez nous contacter à : <strong className='underline'>support@partof.ch</strong>.
                    </p>
                </section>

                <section className="mt-8">
                    <p className="text-center text-gray-500">
                        Merci d'utiliser PartOf !
                    </p>
                </section>
            </div>
        </div>
    )
}

export default Policy
